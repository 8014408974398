var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"aware",rawName:"v-aware"}],staticClass:"flexible-experts-outer wrapper",on:{"viewport":_vm.onResize}},[(_vm.popupContent)?_c('GPopup',{ref:"popup",attrs:{"content":_vm.popupContent,"template":"expert"},on:{"popup:close":_vm.closePopup}}):_vm._e(),_c('div',{staticClass:"flexible-experts"},[_c('div',{staticClass:"flexible-experts__content"},[(_vm.content)?_c('GTitle',{staticClass:"h3 mb-s",attrs:{"tag":"h2","content":_vm.content.title}}):_vm._e(),(_vm.content)?_c('GIntro',{attrs:{"content":_vm.content.htmltext}}):_vm._e()],1),_c('div',{ref:"slider",staticClass:"flexible-experts__items"},[_vm._l((_vm.content.experts),function(expert,index){return _c('div',{key:expert.url,ref:"items",refInFor:true,staticClass:"flexible-experts__item"},[_c('GAction',{staticClass:"flexible-experts__item__button",attrs:{"content":{
            label: _vm.$t('see-testimonial'),
            modifiers:
              index == 0 || index == 6 || index == 9
                ? ['filled']
                : ['no-label', 'filled'],
            icon: 'rightArrow',
            tag: 'button',
          },"prevent-tracking":true},nativeOn:{"click":function($event){return _vm.onClick(expert)}}}),_c('UiPicture',{staticClass:"flexible-experts__item__picture br-small",attrs:{"cover":true,"content":expert.video.picture,"sets":[
            '240',
            '320',
            '480',
            '640',
            '960',
            '1280',
            '1600',
            '1920',
            '2240' ]}}),(expert.video.label)?_c('div',{staticClass:"flexible-experts__item__legend small mt-xxs",domProps:{"innerHTML":_vm._s(expert.video.label)}}):_vm._e()],1)}),_c('div',{ref:"text",staticClass:"flexible-experts__intro"},[(_vm.content)?_c('GTitle',{staticClass:"h3",attrs:{"tag":"h2","content":_vm.content.title}}):_vm._e(),(_vm.content)?_c('GIntro',{attrs:{"content":_vm.content.htmltext}}):_vm._e()],1)],2),_c('div',{staticClass:"flexible-experts__slides__nav"},[_c('GAction',{ref:"prevButton",staticClass:"flexible-experts__slides__nav__button",attrs:{"content":{
          label: 'Précédent',
          tag: 'button',
          modifiers: ['no-label'],
          icon: 'leftArrow',
        },"aria-label":"Précédent","disabled":_vm.index === 0},nativeOn:{"click":function($event){return _vm.prev.apply(null, arguments)}}}),_c('GAction',{ref:"nextButton",staticClass:"flexible-experts__slides__nav__button",attrs:{"content":{
          label: 'Suivant',
          tag: 'button',
          modifiers: ['no-label'],
          icon: 'arrow',
        },"aria-label":"Suivant","disabled":!_vm.canSlide},nativeOn:{"click":function($event){return _vm.next.apply(null, arguments)}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }